import {getHttpClient} from '../modules/httpClient.js';

class VideoTranscript extends HTMLElement {
    /** Called each time the element is added to the document. */
    connectedCallback() {
        this.#insertTranscript();
    }

    /**
     * Fetches transcript from backend and formats that
     * into a usable template to append to our details element
     * @returns {Promise<void>}
     */
    async #insertTranscript() {
        const detailContent = this.querySelector('[data-details]');

        /**
         * In Safari, the element is sometimes not visible, using BugSnag to investigate.
         * Update 2024-02-29: Also happens in Chrome on Windows 10, so not necessarily a browser-specific issue.
         */
        if (!detailContent) {
            const detail = new Error('Detailed list not found');
            detail.metadata = {htmlContent: {innerHTML: this.innerHTML}};
            window.dispatchEvent(new CustomEvent('log:error', {detail}));
            return;
        }

        const videoId = this.getAttribute('for');
        const videoTranscript = typeof videoId === 'string' ? document.getElementById(videoId)?.querySelector('track[type="descriptions"]') : null;
        if (!videoTranscript) {
            this.#displayStub(detailContent, 'Video Transcript not found');
            return;
        }

        try {
            const transcriptText = await getHttpClient().get(`${videoTranscript.src}`);
            this.#renderTranscriptItems(transcriptText, detailContent);
        } catch (error) {
            this.#displayStub(detailContent, 'Not available at the moment. Please try again later.');
            window.dispatchEvent(new CustomEvent('log:error', {detail: error}));
        }
    }

    /**
     * @param {HTMLOListElement} detailContent
     * @param {string} message
     */
    #displayStub(detailContent, message) {
        detailContent.innerHTML = `<li>${message}</li>`;
    }

    /**
     * Renders transcript from fetched content
     * @param {string} content Transcript content fetched
     * @param {HTMLElement} detailContent
     */
    #renderTranscriptItems(content, detailContent) {
        const template = this.querySelector('template[data-detail-content]');
        // Clear placeholder
        detailContent.innerHTML = '';
        const transcriptChunks = content.split('\n\n').slice(1);
        transcriptChunks.forEach((transcriptChunk) => {
            const parsedTranscriptArray = transcriptChunk.split('\n');
            const time = parsedTranscriptArray.shift().replaceAll(/\.\d{3}/g, '');
            const transcriptParagraph = parsedTranscriptArray.join(' ');
            const templateClone = template.content.cloneNode(true);
            templateClone.querySelector('[data-transcript-time]').textContent = time;
            templateClone.querySelector('[data-transcript-text]').textContent = transcriptParagraph;
            detailContent.append(templateClone);
        });
    }
}

customElements.define('ixdf-video-transcript', VideoTranscript);
