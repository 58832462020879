/**
 * @module tooltip
 * @description Initializes tooltips and prevents native tooltips from displaying
 * @example Tooltip.applyTo(htmlElementDynamicallyAddedToLayout);
 */

export class Tooltip {
    /**
     * Initializes all tooltips. It also adds
     * support for border collisions, by adding a class to control
     * the layout of tooltips too close to screen edges.
     *
     * @param {HTMLElement} [container]
     */
    static applyTo(container = document.body) {
        const windowWidth = window.innerWidth;
        const rightBorderCollisionClass = 'tip-rtl';

        container.querySelectorAll('[data-tip]').forEach((tooltip) => {
            if (tooltip.classList.contains('tip-left')) return;

            // Get the element's width and offset
            const {left, width} = tooltip.getBoundingClientRect();

            const maxRightOffset = tooltip.classList.contains('tip-wide') ? 350 : 230;

            // If the element is too close to the right edge,
            // use a rtl tooltip instead so it doesn't overflow
            // the page and hide.
            const rightEdgeOffset = windowWidth - left;

            if (rightEdgeOffset < maxRightOffset) {
                tooltip.classList.add(rightBorderCollisionClass);
            }

            // For large elements, horizontally center the tooltip
            const isLargeElement = width > 230;
            if (isLargeElement) {
                tooltip.classList.add('tip-center');
            }
        });
    }
}
