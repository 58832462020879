import {Auth} from '../modules/auth.js';
import {isSmallScreenOnly} from '../modules/breakpoint.js';

const BUFFER_OF_EXPANDED_HEADER = 48;
const MOBILE_COLLAPSED_CLASS = 'mobileHeader--collapsed';
const DESKTOP_COLLAPSED_CLASS = 'navigationHeader--collapsed';
const BODY_COLLAPSED_CLASS = 'hasCollapsedHeader';
const HEADER_COLLAPSE_EVENT_NAME = 'headerCollapse';
const HEADER_EXPAND_EVENT_NAME = 'headerExpand';

let previousScrollY = window.scrollY;
let previousDirection = 'down';
let scrollDistanceForCurrentDirection = 0;
let documentObserver = null;
let scheduledAnimationFrame = false;

/** [no description; please add] */
function addScrollListener() {
    const options = detectPassiveModeSupport() ? {passive: true} : false;
    window.addEventListener('scroll', onScroll, options);
}

/** [no description; please add] */
function onScroll() {
    if (scheduledAnimationFrame) return;
    scheduledAnimationFrame = true;

    requestAnimationFrame(() => {
        determineScrollDirection();
        toggleHeaderState();
    });
}

/**
 * Passive even listener feature detection
 * @see https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
 * @returns {boolean}
 */
function detectPassiveModeSupport() {
    let supportsPassive = false;
    try {
        const options = Object.defineProperty({}, 'passive', {
            get: () => (supportsPassive = true),
        });
        window.addEventListener('test', null, options);
        window.removeEventListener('test', null, options);
    } catch (error) {
        /**/
    }
    return supportsPassive;
}

/** [no description; please add] */
function determineScrollDirection() {
    scheduledAnimationFrame = false;
    const newScrollY = window.scrollY;
    const scrollDelta = previousScrollY - newScrollY;
    const newDirection = scrollDelta < 0 ? 'down' : 'up';

    if (previousDirection !== newDirection) {
        scrollDistanceForCurrentDirection = 0;
    }

    scrollDistanceForCurrentDirection += Math.abs(scrollDelta);

    previousDirection = newDirection;
    previousScrollY = newScrollY;
}

/**
 * @returns {boolean}
 */
function isMobileHeaderCollapsible() {
    const {pathname} = window.location;
    // we want to hide for logged-in users and on login page
    return Auth.check() || pathname.includes('/login');
}

/** [no description; please add] */
function collapseMobileHeader() {
    document.querySelector('.js-mobileHeader')?.classList.add(MOBILE_COLLAPSED_CLASS);
    document.body.classList.add(BODY_COLLAPSED_CLASS);
}

/** [no description; please add] */
function expandMobileHeader() {
    document.querySelector('.js-mobileHeader')?.classList.remove(MOBILE_COLLAPSED_CLASS);
    document.body.classList.remove(BODY_COLLAPSED_CLASS);
}

/**
 * @returns {boolean}
 */
function isOffCanvasMenuOpen() {
    return document.querySelector('.offCanvasWrapper')?.classList.contains('menu-open');
}

/**
 * Determines whether the user has scrolled past a specified threshold (150px) and
 * dynamically manages the 'below-the-fold' CSS class accordingly.
 *
 * "Above the fold" ==> user has not scrolled (no class is added here)
 * "below-the-fold" ==> user has scrolled 150px+ (class is added to `body`)
 * This allows for styling changes or actions based on scroll position.
 */
function isAboveTheFold() {
    // Below the fold
    if (window.scrollY > 150) {
        if (!document.body.classList.contains('below-the-fold')) {
            document.body.classList.add('below-the-fold');
        }
    } else {
        // Above the fold
        if (document.body.classList.contains('below-the-fold')) {
            document.body.classList.remove('below-the-fold');
        }
    }
}

/** [no description; please add] */
function toggleHeaderState() {
    isAboveTheFold();
    if (isMobileHeaderCollapsible()) {
        if (window.scrollY <= 0) {
            expandMobileHeader();
            return;
        }

        if (scrollDistanceForCurrentDirection < BUFFER_OF_EXPANDED_HEADER) {
            return;
        }

        if (isOffCanvasMenuOpen()) {
            return;
        }

        if (previousDirection === 'down') {
            collapseMobileHeader();
        }

        if (previousDirection === 'up') {
            expandMobileHeader();
        }
    }
}

/** [no description; please add] */
function collapseDesktopHeader() {
    document.querySelector('.js-navigationHeader').classList.add(DESKTOP_COLLAPSED_CLASS);
    document.body.classList.add(BODY_COLLAPSED_CLASS);
    document.dispatchEvent(new CustomEvent(HEADER_COLLAPSE_EVENT_NAME));
}

/** [no description; please add] */
function expandDesktopHeader() {
    document.querySelector('.js-navigationHeader').classList.remove(DESKTOP_COLLAPSED_CLASS);
    document.body.classList.remove(BODY_COLLAPSED_CLASS);
    document.dispatchEvent(new CustomEvent(HEADER_EXPAND_EVENT_NAME));
}

/**
 * @returns {IntersectionObserver}
 */
function createObserver() {
    return new IntersectionObserver(
        (records) => {
            for (const record of records) {
                const targetInfo = record.boundingClientRect;
                const rootBoundsInfo = record.rootBounds;
                const MINIMUM_PIXEL_DIFFERENCE_FOR_EXPANSION = 0;

                if (targetInfo && rootBoundsInfo) {
                    if (targetInfo.bottom - rootBoundsInfo.top >= MINIMUM_PIXEL_DIFFERENCE_FOR_EXPANSION) {
                        expandDesktopHeader();
                    } else {
                        collapseDesktopHeader();
                    }
                }
            }
        },
        {threshold: [0]}
    );
}

/**
 * Use intersection observer to listen to when element is sticky.
 * Based on this post: https://developers.google.com/web/updates/2017/09/sticky-headers
 */
function observeDesktopHeader() {
    const stickySentinel = document.querySelector('[data-secondary-navigation-header]');
    window.addEventListener('scroll', isAboveTheFold);
    if (stickySentinel && documentObserver === null) {
        documentObserver = createObserver();
        documentObserver.observe(stickySentinel);
    }
}

/** [no description; please add] */
function initiateListeners() {
    if (isSmallScreenOnly()) {
        addScrollListener();
    } else {
        observeDesktopHeader();
    }
}

initiateListeners();

window.addEventListener('resize', initiateListeners);
