/**
 * @module form
 * @description Set styles for input elements, filter user's input to form elements
 */

/**
 * Set Social Edit Interaction
 */
function setSocialEditInteraction() {
    const socialEdit = document.querySelector('.js-social-edit');
    if (!socialEdit) return;
    const inputs = socialEdit.querySelectorAll('input[type=text], input[type=url], input[type=email]');
    inputs.forEach((input) => {
        input.addEventListener('focus', (event) => {
            const socialElement = /** @type {HTMLElement} */ (event.currentTarget).closest('.js-social-edit');
            socialElement.classList.toggle('focus');
        });
        input.addEventListener('blur', (event) => {
            const socialElement = /** @type {HTMLElement} */ (event.currentTarget).closest('.js-social-edit');
            socialElement.classList.toggle('focus');
        });
    });
}

/**
 * Initialize component, register all event handlers
 */
export function restyleAllInputElements() {
    const disabledSelectors = ['a.disabled', '.button.disabled', 'button.disabled', 'input[type=submit].disabled'].join(
        ', '
    );

    document.querySelectorAll(disabledSelectors).forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
        });
    });

    setSocialEditInteraction();
}
