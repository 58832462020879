/**
 * @module datepicker
 * @description Implements custom element date-picker
 */

/**
 * Helper to repeat a function and join resulting array as a string
 * @param {number} times number of times to repeat
 * @param {Function} func function to execute on each step
 * @returns {string} joined result
 */
function repeat(times, func) {
    return [...new Array(times).keys()].map(func).join('');
}

/**
 * Returns YYYY-MM-DD value for a locale date
 * @param {Date} date value to use
 * @returns {?string} YYYY-MM-DD representation
 */
function formatLocaleDate(date) {
    if (!date) return null;

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, -date.getTimezoneOffset())
        .toISOString()
        .split('T')[0];
}

/**
 * Parses YYYY-MM-DD string as a locale date
 * @param {string} value value to parse
 * @returns {?Date} a date value or null if string is invalid
 */
function parseLocaleDate(value) {
    const [year, month, date] = (value || '?').split(/\D/).map((s) => parseInt(s));
    const dateValue = new Date(year, month - 1, date, 0, 0);
    return isNaN(dateValue.getTime()) ? null : dateValue;
}

/**
 * Get a localized string for numeric weekday value
 * @param {number} value numeric weekday
 * @param {object} options localization options
 * @returns {string} locale string for weekday
 */
function localeWeekday(value, options) {
    const date = new Date(1971, 1, value + (options.hour12 ? 0 : 1));
    return date.toLocaleString(options.locale, {weekday: 'short'});
}

/**
 * Get a localized string for numeric month value
 * @param {number} value numeric month
 * @param {object} options localization options
 * @returns {string} locale string for month
 */
function localeMonth(value, options) {
    const date = new Date(25e8 * (value + 1));
    return date.toLocaleString(options.locale, {month: 'short'});
}

const datepickerStylesMeta = document.querySelector('meta[name="datepicker-styles-link"]');

class DatePicker extends HTMLElement {
    constructor() {
        super();

        this._input = this.querySelector('input');
        this._button = this.querySelector('button');

        this._shadowRoot = this.attachShadow({mode: 'open'});
        this._shadowRoot.innerHTML = `
<link href="${datepickerStylesMeta.content}" rel="stylesheet">
<slot></slot>
        `;

        this._initFormatOptions();

        if (this._input) {
            this._valueElement = document.createElement('input');
            // can't use <input type=hidden> because defaultValue
            // isn't updated on form reset as it is for text input
            this._valueElement.type = 'text';
            this._valueElement.hidden = true;
            this._valueElement.name = this._input.name;
            this._valueElement.disabled = this._input.disabled;
            this._valueElement.defaultValue = this._input.defaultValue;
            // respect form attribute value
            if (this._input.hasAttribute('form')) {
                this._valueElement.setAttribute('form', this._input.getAttribute('form'));
            }
            this._input.addEventListener('focus', this.showPicker.bind(this));
            this._input.addEventListener('click', this.showPicker.bind(this));
            this._input.addEventListener('blur', this.hidePicker.bind(this));
            this._input.addEventListener('keydown', this._onKeydown.bind(this));
            // update visible value in text input
            this.dateValue = parseLocaleDate(this._input.value);
            // do not popup keyboard on mobile devices
            this._input.setAttribute('inputmode', 'none');
            // need to set readonly attribute as well to prevent
            // visible date modification with the cut feature
            this._input.readOnly = true;
            // <input> is used to display visible date value in
            // a human-friendly format. Real value is stored in
            // hidden <input type=text> with the original name
            this._input.removeAttribute('name');
            // play nice with <button type=reset>
            this._input.defaultValue = this._input.value;

            this.insertBefore(this._valueElement, this._input.nextSibling);
        }

        if (this._button) {
            this._button.addEventListener('click', this.showPicker.bind(this));
            this._button.addEventListener('blur', this.hidePicker.bind(this));

            this._valueElement = this._button;
        }
    }

    /**
     * Gets current picker date as string
     * @returns {?string} current string value
     */
    get formValue() {
        return this._valueElement.value;
    }

    /**
     * Sets current picker date as string
     * @param {string} value new string value
     */
    set formValue(value) {
        const dateValue = parseLocaleDate(value);
        if (dateValue) {
            this.dateValue = dateValue;
        }
    }

    /**
     * Gets current picker date
     * @returns {?Date} current date value
     */
    get dateValue() {
        return parseLocaleDate(this.formValue);
    }

    /**
     * Sets date value for picker
     * @param {Date} date new date value
     */
    set dateValue(date) {
        this._valueElement.value = formatLocaleDate(date) || '';

        if (this._input) {
            this._input.value = date && date.toLocaleString(this._formatOptions.locale, this._formatOptions) || '';
            // notify any value change listeners, recheck validity
            this._input.dispatchEvent(new Event('change', {bubbles: true}));
        }

        if (this._button) {
            // notify any value change listeners
            this._button.dispatchEvent(new Event('change', {bubbles: true}));
        }
    }

    /**
     * Creates date picker shadow dom
     * @param {number} [defaultYearDelta] delta used to determine number of years when no min/max attributes were set
     */
    _initPicker(defaultYearDelta = 30) {
        const now = new Date();
        const minDate = this._getLimitationDate('min');
        const maxDate = this._getLimitationDate('max');
        let startYear = minDate ? minDate.getFullYear() : now.getFullYear() - defaultYearDelta;
        let endYear = maxDate ? maxDate.getFullYear() : now.getFullYear() + defaultYearDelta;
        // append picker HTML to shadow dom
        this._shadowRoot.querySelector('slot').insertAdjacentHTML(
            'beforebegin',
            `
<div aria-hidden="true" aria-modal="true" aria-labelledby="#caption">
    <header>
        <button part="left-btn" tabindex="-1"></button>
        <time id="caption" aria-live="polite"></time>
        <button part="right-btn" tabindex="-1"></button>
    </header>
    <table role="grid" aria-labelledby="#caption">
        <thead id="weekdays">${repeat(7, (i) => `<th>${localeWeekday(i, this._formatOptions)}</th>`)}</thead>
        <tbody id="days">${`<tr>${'<td data-timestamp>'.repeat(7)}</tr>`.repeat(6)}</tbody>
    </table>
    <div aria-hidden="true" aria-labelledby="#caption">
        <ol id="months">${repeat(12, (i) => `<li data-month="${i}">${localeMonth(i, this._formatOptions)}`)}</ol>
        <ol id="years">${repeat(endYear - startYear + 1, (i) => {
                return `<li data-year="${startYear + i}">${startYear + i}</li>`;
            })}</ol>
    </div>
</div>  `
        );

        this._picker = this._shadowRoot.querySelector('[aria-modal="true"]');
        this._caption = this._shadowRoot.querySelector('[aria-live="polite"]');
        this._picker.querySelectorAll('[aria-labelledby="#caption"]').forEach((element) => {
            if (element.getAttribute('role') === 'grid') {
                this._dayPicker = element;
            } else {
                this._yearPicker = element;
            }
        });

        this._picker.addEventListener('mousedown', this._onMouseDown.bind(this));
        this._picker.addEventListener('contextmenu', (event) => event.preventDefault());
    }

    /**
     * Displays date picker
     */
    showPicker() {
        if (!this._picker) {
            this._initPicker();
        } else if (this._picker.getAttribute('aria-hidden') !== 'true') {
            // early return because picker is visible already
            return;
        }

        const startElement = this._input || this._button;
        const rootElement = document.documentElement;
        const pickerOffset = this._picker.getBoundingClientRect();
        const inputOffset = startElement.getBoundingClientRect();
        // set picker position depending on current visible area
        let marginTop = inputOffset.height;
        if (rootElement.clientHeight < inputOffset.bottom + pickerOffset.height) {
            marginTop = -pickerOffset.height;
        }
        this._picker.style.marginTop = marginTop + 'px';

        this._renderCalendarPicker(parseLocaleDate(this._valueElement.value) || new Date());
        // display picker
        this._picker.removeAttribute('aria-hidden');
    }

    /**
     * Hides date picker
     */
    hidePicker() {
        this._picker.setAttribute('aria-hidden', 'true');
        this._resetPickerMode();
    }

    /**
     * Resets date picker mode
     */
    _resetPickerMode() {
        this._picker.querySelectorAll('[aria-labelledby="#caption"]').forEach((element) => {
            element.setAttribute('aria-hidden', element !== this._dayPicker);
        });
    }

    /**
     * Toggles date picker mode
     */
    _togglePickerMode() {
        this._picker.querySelectorAll('[aria-labelledby="#caption"]').forEach((element) => {
            const currentDate = parseLocaleDate(this._valueElement.value) || new Date();
            const hidden = element.getAttribute('aria-hidden') === 'true';
            if (element === this._dayPicker) {
                if (hidden) {
                    this._renderCalendarPicker(currentDate);
                }
            } else {
                if (hidden) {
                    this._renderAdvancedPicker(currentDate);
                }
            }
            element.setAttribute('aria-hidden', !hidden);
        });
    }

    /**
     * Handler for keyboard down on attached input element
     * @param {KeyboardEvent} event
     */
    _onKeydown(event) {
        const key = event.key;

        if (key === 'Enter') {
            this.hidePicker();
        } else if (key === ' ') {
            // disable scroll change
            event.preventDefault();

            this.showPicker();
        } else if (key === 'Alt') {
            this._togglePickerMode();
        } else if (key.includes('Arrow')) {
            // disable scroll change via arrows
            event.preventDefault();

            let offset = 0;
            switch (key) {
                case 'ArrowDown': {
                    offset = 7;

                    break;
                }
                case 'ArrowUp': {
                    offset = -7;

                    break;
                }
                case 'ArrowLeft': {
                    offset = -1;

                    break;
                }
                case 'ArrowRight': {
                    offset = 1;

                    break;
                }
                // No default
            }
            if (!offset) return;

            const captionDate = this._getCaptionDate();
            const advancedMode = this._dayPicker.getAttribute('aria-hidden') === 'true';
            if (advancedMode) {
                if (Math.abs(offset) === 7) {
                    captionDate.setMonth(captionDate.getMonth() + offset / 7);
                } else {
                    captionDate.setFullYear(captionDate.getFullYear() + offset);
                }
            } else {
                captionDate.setDate(captionDate.getDate() + offset);
            }
            if (this._isValidValue(captionDate)) {
                this.dateValue = captionDate;
                if (advancedMode) {
                    this._renderAdvancedPicker(captionDate);
                } else {
                    this._renderCalendarPicker(captionDate);
                }
            }
        } else if (key === 'Backspace') {
            this.dateValue = null;
            this._resetPickerMode();
            this._renderCalendarPicker(new Date());
        }
    }

    /**
     * Handler for mouse and touch click on attached input element
     * @param {MouseEvent} event
     */
    _onMouseDown(event) {
        const target = event.target;
        // disable default behavior so input doesn't loose focus
        event.preventDefault();
        // skip right/middle mouse button clicks
        if (event.button) return;

        if (target === this._caption) {
            this._togglePickerMode();
        } else if (target.matches('button')) {
            this._clickButton(target);
        } else if (target.matches('[data-timestamp]')) {
            this._clickDate(target);
        } else if (target.matches('[data-year],[data-month]')) {
            this._clickMonthYear(target);
        }
    }

    /**
     * Handler for click on prev/next buttons in picker caption
     * @param {HTMLElement} target button was clicked
     */
    _clickButton(target) {
        const captionDate = this._getCaptionDate();
        const sign = target.matches('[part^="left"]') ? -1 : 1;
        const advancedMode = this._dayPicker.getAttribute('aria-hidden') === 'true';
        if (advancedMode) {
            captionDate.setFullYear(captionDate.getFullYear() + sign);
        } else {
            captionDate.setMonth(captionDate.getMonth() + sign);
        }
        if (this._isValidValue(captionDate)) {
            if (advancedMode) {
                this._renderAdvancedPicker(captionDate);
                this.dateValue = captionDate;
            } else {
                this._renderCalendarPicker(captionDate);
            }
        }
    }

    /**
     * Handler for click on a calendar day element
     * @param {HTMLElement} target element was clicked
     */
    _clickDate(target) {
        if (target.getAttribute('aria-disabled') !== 'true') {
            this.dateValue = new Date(+target.dataset.timestamp);
            this.hidePicker();
        }
    }

    /**
     * Handler for click on a month/year item element
     * @param {HTMLElement} target element was clicked
     */
    _clickMonthYear(target) {
        const month = +target.dataset.month;
        const year = +target.dataset.year;
        if (month >= 0 || year >= 0) {
            const captionDate = this._getCaptionDate();
            if (!isNaN(month)) {
                captionDate.setMonth(month);
            }
            if (!isNaN(year)) {
                captionDate.setFullYear(year);
            }
            if (this._isValidValue(captionDate)) {
                this._renderAdvancedPicker(captionDate, false);
                this.dateValue = captionDate;
            }
        }
    }

    /**
     * Renders picker in simple (calendate) mode
     * @param {Date} captionDate current date in caption
     */
    _renderCalendarPicker(captionDate) {
        const now = new Date();
        const currentDate = parseLocaleDate(this._valueElement.value);
        const minDate = this._getLimitationDate('min');
        const maxDate = this._getLimitationDate('max');
        const iterDate = new Date(captionDate.getFullYear(), captionDate.getMonth());
        // move to the beginning of the first week in the current month
        iterDate.setDate((this._formatOptions.hour12 ? 0 : iterDate.getDay() === 0 ? -6 : 1) - iterDate.getDay());

        this._dayPicker.querySelectorAll('[data-timestamp]').forEach((cell) => {
            iterDate.setDate(iterDate.getDate() + 1);

            if (iterDate.getMonth() === captionDate.getMonth()) {
                if (
                    currentDate &&
                    iterDate.getMonth() === currentDate.getMonth() &&
                    iterDate.getDate() === currentDate.getDate()
                ) {
                    cell.setAttribute('aria-selected', 'true');
                } else {
                    cell.setAttribute('aria-selected', 'false');
                }
            } else {
                cell.removeAttribute('aria-selected');
            }

            if (
                iterDate.getFullYear() === now.getFullYear() &&
                iterDate.getMonth() === now.getMonth() &&
                iterDate.getDate() === now.getDate()
            ) {
                cell.setAttribute('aria-current', 'date');
            } else {
                cell.removeAttribute('aria-current');
            }

            if ((minDate && iterDate < minDate) || (maxDate && iterDate > maxDate)) {
                cell.setAttribute('aria-disabled', 'true');
            } else {
                cell.removeAttribute('aria-disabled');
            }

            cell.textContent = iterDate.getDate();
            cell.dataset.timestamp = iterDate.getTime();
        });
        // update visible value in caption
        this._setCaptionDate(captionDate);
    }

    /**
     * Renders picker in advanced (month/year) mode
     * @param {Date} captionDate current date in caption
     * @param {boolean} [syncScroll] flag to determine if scroll position should be insync
     */
    _renderAdvancedPicker(captionDate, syncScroll = true) {
        this._yearPicker.querySelectorAll('[aria-selected]').forEach((selectedElement) => {
            selectedElement.removeAttribute('aria-selected');
        });

        if (captionDate) {
            const monthItem = this._yearPicker.querySelector(`[data-month="${captionDate.getMonth()}"]`);
            const yearItem = this._yearPicker.querySelector(`[data-year="${captionDate.getFullYear()}"]`);
            monthItem.setAttribute('aria-selected', 'true');
            yearItem.setAttribute('aria-selected', 'true');
            if (syncScroll) {
                monthItem.parentNode.scrollTop = monthItem.offsetTop;
                yearItem.parentNode.scrollTop = yearItem.offsetTop;
            }
            this._setCaptionDate(captionDate);
        }
    }

    /**
     * Returns current date in caption
     * @returns {Date}
     */
    _getCaptionDate() {
        return new Date(this._caption.getAttribute('datetime'));
    }

    /**
     * Sets current date in caption
     * @param {Date} captionDate value to set
     */
    _setCaptionDate(captionDate) {
        this._caption.textContent = captionDate.toLocaleString(this._formatOptions.locale, {
            month: 'long',
            year: 'numeric',
        });
        this._caption.setAttribute('datetime', captionDate.toISOString());
    }

    _initFormatOptions() {
        const lang = this._input?.lang || document.documentElement.lang;
        const dateStyle = this.getAttribute('format');
        let dateFormat;
        try {
            // We perform severals checks here:
            // 1) verify lang attribute is supported by browser
            // 2) verify format attribute is one from "full","long","medium","short"
            dateFormat = new Intl.DateTimeFormat(lang, dateStyle ? {dateStyle} : {});
        } catch (error) {
            console.warn('Fallback to default date format because of error:', error);
            // fallback to default date format options
            dateFormat = new Intl.DateTimeFormat();
        }
        this._formatOptions = dateFormat.resolvedOptions();
        // Fix Safari-specific TypeError: dateStyle and timeStyle
        // may not be used with other DateTimeFormat options
        // https://github.com/InteractionDesignFoundation/IxDF-web/issues/19069
        try {
            new Date().toLocaleString(this._formatOptions.locale, this._formatOptions);
        } catch (error) {
            delete this._formatOptions.dateStyle;
        }
    }

    /**
     * Verify if date value satisfies min/max limits
     * @param {Date} dateValue value to test
     * @returns {boolean} true when value is valid
     */
    _isValidValue(dateValue) {
        const minDate = this._getLimitationDate('min');
        const maxDate = this._getLimitationDate('max');
        return !((minDate && dateValue < minDate) || (maxDate && dateValue > maxDate));
    }

    /**
     * Returns a limitation Date by name
     * @param {string} name attribute name to read
     * @returns {?Date} attribute value as date
     */
    _getLimitationDate(name) {
        if (this._input) {
            return parseLocaleDate(this._input.getAttribute(name));
        } else {
            return null;
        }
    }
}

customElements.define('date-picker', DatePicker);
