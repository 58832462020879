/**
 * please think twice before adding a new app-level dependency,
 * it'll have effect to the pageload and parsing time of the app
 */
/** @type {import('../../node_modules/htmx.org/dist/htmx.esm.d.ts').htmx} htmx */

import {Button} from './modules/button.js';
import {Notification} from './modules/notification.js';
import {PageSpinner, Spinner} from './modules/spinner.js';
import {restyleAllInputElements} from './modules/form.js';
import {Tooltip} from './modules/tooltip.js';
import {CookieManager} from './modules/cookie.js';
import {Auth} from './modules/auth.js';
import {AnalyticsListener} from './modules/analyticsListener.js';
import {manageNonSubscriberContent} from './modules/manageNonSubscriberContent.js';

import './behaviors/search/modalSearch.js';
import './behaviors/cta.js';
import './behaviors/formValidation.js';
import './behaviors/header.js';
import './behaviors/lazyLoader.js';
import './behaviors/offcanvas.js';
import './behaviors/passwordField.js';
import './behaviors/stickyToBottomContainer.js';
import './behaviors/tabs.js';

import './components/adArea/adArea.js';
import './components/countdown.js';
import './components/countdownTimer.js';
import './components/lottieAnimation.js';
import './components/videoTranscript.js';
import './components/tocContainer.js';
import './components/datepicker.js';

import htmx from 'htmx.org';
import dialogPolyfill from 'dialog-polyfill';
import {setupDialogSupport} from './modules/dialog.js';

/** Selector for elements that represent playable videos on a page. */
const VIDEO_ELEMENTS_SELECTOR =
    'video,iframe[data-src*=youtube],iframe[src*=youtube],iframe[src*=vimeo],iframe[data-src*=vimeo]';

/**
 * Display Alerts based on elements with data-alert attribute
 */
function displayAlerts() {
    [...document.querySelectorAll('[data-alert]')].forEach(
        /** @param {HTMLElement} element */
        (element) => {
            Notification[element.dataset.alertType](
                {message: element.dataset.alertMessage, title: element.dataset.alertTitle},
                element.parentElement
            );
        }
    );
}

/**
 * Listen notification:show events to display Notifications
 * Good to:
 * - not import Notification in multiple js files
 * - use different Notifications on different contexts (e.g. on Nova)
 */
function listenNotifications() {
    document.addEventListener('notification:show', function (event) {
        let messageType = event.detail.type || 'error';
        if (! ['success', 'info', 'warning', 'error'].includes(messageType)) {
            messageType = 'error';
        }

        // Notification.error()
        Notification[messageType](
            event.detail.message || `undefined ${messageType} message}`,
            event.target
        );
    });
}

/**
 * Listen spinner:xxx events to display Spinner
 * Good to:
 * - not import Spinner in multiple js files
 * - use different Spinner on different contexts (e.g. on Nova)
 */
function listenSpinners() {
    document.addEventListener('spinner:start', function (event) {
        const message = event.detail.message || 'Loading...';

        event.target instanceof HTMLDocument
            ? PageSpinner.start(message)
            : Spinner.createAndStart(event.target, message);
    });

    document.addEventListener('spinner:stop', function (event) {
        event.target instanceof HTMLDocument
            ? PageSpinner.stop()
            : new Spinner(event.target).stop();
    });
}

/**
 * Register event handler to log important events to an external service
 * Events dispatched using code like: window.dispatchEvent(new CustomEvent('log:warning', {detail}));
 */
function logEvents() {
    window.addEventListener('log:error', handleLog);
    window.addEventListener('log:warning', handleLog);
    window.addEventListener('log:info', handleLog);

    /**
     * @param {Event} event
     */
    function handleLog(event) {
        const severity = event.type.split(':')[1];

        window.addEventListener(
            `log:${severity}`,
            /** @param {CustomEvent} event */
            (event) => {
                // @ts-ignore
                const bugsnag = window.Bugsnag;
                if (!bugsnag) {
                    // eslint-disable-next-line no-console
                    console[severity === 'warning' ? 'warn' : severity](event.detail);
                    return;
                }

                /** @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/ */
                let notification = event.detail;
                if (!notification) {
                    // eslint-disable-next-line no-console
                    console.error('Event detail must be specified for logging');
                    return;
                }

                bugsnag.notify(notification, (event) => {
                    event.severity = severity;

                    const metadata = notification.metadata;
                    if (typeof metadata === 'object') {
                        Object.keys(metadata).forEach((key) => {
                            event.addMetadata(key, metadata[key]);
                        });
                    }
                });
            }
        );
    }
}

/**
 * Preloads a source code for video component only if there are any videos on the page.
 * The initialization of a player instance for videos is up to {@see components/video}.
 */
function preloadVideoComponent() {
    const firstVideo = document.querySelector(VIDEO_ELEMENTS_SELECTOR);
    if (!firstVideo) return;

    /**
     * Delay (in ms) which is used to postpone preloading of video component's source code when the current page
     * contains at least a single video to play. The reason to have such a delay is to be sure all major sources
     * have been loaded already on the initial page load, and it won't consume extra CPU time in a critical moment.
     * Meanwhile, if the page is being scrolled to any of its videos then the video component's source code will be
     * requested by {@see modules/lazyLoader} immediately without any delay using ECMAScript dynamic import.
     */

    const observer = new IntersectionObserver((entries) => {
        // TODO: Check if this observer is really required
        // Another option: (()=>{import('./behaviors/video.js')})();
        // Slack Thread: https://interaction-design.slack.com/archives/C04AT30UJ/p1719931965704159
        if (entries[0].isIntersecting) {
            import('./behaviors/video.js');
            observer.disconnect();
        }
    }, {
        rootMargin: '200px', // Start loading when video is near viewport
    });

    observer.observe(firstVideo);
}

/** */
function lazyLoadCollapsibleTextComponents() {
    const collapsibleOverflowTextComponents = document.querySelectorAll('[data-collapsible-overflow-text]');
    if (collapsibleOverflowTextComponents.length) import('./components/collapsibleOverflowText.js');
}

/** */
function initPageAnnouncementCloseButton() {
    const pageAnnouncementCloseButtons = [...document.querySelectorAll('[data-page-announcement-close-button]')];

    pageAnnouncementCloseButtons.forEach((pageAnnouncementCloseButton) => {
        pageAnnouncementCloseButton.addEventListener('click', (event) => {
            const eventTarget = /** @type {HTMLElement} */ (event.target);
            /** @type {HTMLElement} */
            const pageAnnouncement = eventTarget.closest('[data-page-announcement]');
            if (pageAnnouncement.dataset.dismissibleId) {
                // @ts-ignore
                CookieManager.write(`dismissed_announcement:${pageAnnouncement.dataset.dismissibleId}`, null, {
                    days: 7,
                    secure: true,
                });
            }

            pageAnnouncement.classList.add('hidden');
        });
    });
}

/**
 * Display Localized Time As Tooltip
 */
function displayLocalizedTimeAsTooltip() {
    const formats = {
        datetime: {
            weekday: 'short',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'long',
            hourCycle: 'h24',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'long',
            hourCycle: 'h12',
        },
    };

    [...document.querySelectorAll('.js-timestampToToolTip')].forEach(
        /** @param {HTMLElement} element */
        (element) => {
            const timestamp = element.dataset.timestamp;
            if (!timestamp) {
                return;
            }
            const format = element.dataset.format || 'datetime';
            const options = formats[format];
            const date = new Date(Number(timestamp) * 1000);
            element.dataset.title = date.toLocaleString(undefined, options);
        }
    );
}

/**
 * Setup service worker
 */
function registerServiceWorker() { // eslint-disable-line no-unused-vars
    if ('serviceWorker' in navigator) {
        // Use the window load event to keep the page load performant
        window.addEventListener('load', () => {
            const serviceWorker = navigator.serviceWorker;
            if (serviceWorker && serviceWorker instanceof ServiceWorkerContainer) {
                serviceWorker.register('/build/sw.js');
            }
        });
    }
}

/** To make the app more interactive with dialogs, the app is listening for some event. */
function listenDialogManagementEvents() {
    /**
     * Experimental functionality. If you found it used in 2025, please remove it.
     * Sometimes it's safer/more useful to emit event instead of writing custom JS logic (e.g. HTMLX, inline onlick JS)
     */
    document.addEventListener('openDialog', (/** @type {CustomEvent} */ event) => {
        const dialog = document.getElementById(event.detail.dialogId);
        if (dialog instanceof HTMLDialogElement) {
            dialog.showModal();
        } else {
            throw new Error(`Dialog with id ${event.detail.dialogId} not found`);
        }
    });
}

/** @see https://htmx.org/docs/ */
function setupHtmx() {
    htmx.config.responseHandling = [
        {code: '429', swap: true, select: '#main-content', ignoreTitle: true}, // custom
        {code: '204', swap: false}, // default
        {code: '[23]..', swap: true}, // default
        {code: '[45]..', swap: false, error: true}, // default
    ];
}

/**
 * Init application
 */
function runApp() {
    setupDialogSupport(dialogPolyfill);
    listenDialogManagementEvents();

    if (Auth.check()) {
        import('./behaviors/sessionVerifier.js');
    }

    Button.bindInteractiveButtons();
    Tooltip.applyTo(document.body);

    restyleAllInputElements();
    displayAlerts();
    listenNotifications();
    listenSpinners();
    logEvents();
    preloadVideoComponent();
    lazyLoadCollapsibleTextComponents();
    initPageAnnouncementCloseButton();
    displayLocalizedTimeAsTooltip();
    manageNonSubscriberContent();
    // registerServiceWorker();

    setupHtmx();

    new AnalyticsListener().registerListeners();
}

// init page when the document is ready
document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', runApp) : runApp();

// @ts-ignore
if (import.meta.env.VITE_APP_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.info(
        '------------------------------------------------------------------------\n\nWant to help us write even better code?\n\nThen come work with us: https://www.interaction-design.org/about/careers\n\n------------------------------------------------------------------------'
    );
} else {
    // Temp code, needed by designers for redesign only
    // Create a dialog list component for development environment
    const dialogListButton = document.getElementById('js-showDialogs');

    // Create the dialog list modal
    const dialogListModal = document.createElement('dialog');
    dialogListModal.id = 'dialogListModal';
    dialogListModal.className = 'rounded-xl shadow-2xl p-0 backdrop:bg-gray-800/60 animate-fadeIn';

    dialogListButton.addEventListener('click', function() {
        // Get all dialogs except staff dialogs and the list modal itself
        const dialogs = document.querySelectorAll('dialog:not(#dialogListModal)');
        const dialogList = [...dialogs]
            .filter(dialog => !dialog.id.startsWith('staff'))
            .map(dialog => {
                const title = dialog.querySelector('h1, h2')?.textContent?.trim() || 'Untitled Dialog';
                const id = dialog.id || 'unknown';
                return `
                    <li class="border-b border-gray-100 last:border-0 transition-colors duration-150">
                        <button
                            class="w-full px-6 py-4 text-left hover:bg-indigo-50 flex justify-between items-center group transition-all duration-200"
                            data-dialog="${id}"
                        >
                            <span class="flex items-center space-x-3">
                                <span class="block w-2 h-2 rounded-full bg-indigo-400 group-hover:bg-indigo-500 transition-colors"></span>
                                <span class="font-medium text-gray-700 group-hover:text-gray-900">${title}</span>
                            </span>
                            <span class="flex items-center space-x-2">
                                <span class="text-sm text-gray-400 group-hover:text-indigo-500 font-mono">${id}</span>
                            </span>
                        </button>
                    </li>
                `;
            }).join('');

        // Update modal content
        dialogListModal.innerHTML = `
            <div class="bg-white rounded-xl overflow-hidden max-w-2xl w-full">
                <div class="flex justify-between items-center px-6 py-4 border-b border-gray-100 bg-white">
                    <span class="flex items-center space-x-3">
                        <h2 class="text-xl font-semibold text-gray-900">Available Dialogs</h2>
                    </span>
                    <button class="text-gray-400 hover:text-gray-600 p-2 hover:bg-gray-100 rounded-lg transition-colors" onclick="this.closest('dialog').close()">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="max-h-[70vh] overflow-y-auto">
                    ${dialogList.length ? `
                        <ul class="divide-y divide-gray-100">
                            ${dialogList}
                        </ul>
                    ` : `
                        <span class="block px-6 py-8 text-center">
                            <p class="text-gray-500">No dialogs available</p>
                        </span>
                    `}
                </div>
            </div>
        `;

        // Show the modal
        if (!document.body.contains(dialogListModal)) {
            document.body.append(dialogListModal);
        }
        dialogListModal.showModal();
    });

    // Handle dialog opening from the list
    dialogListModal.addEventListener('click', function(event) {
        const button = event.target.closest('button[data-dialog]');
        if (button) {
            const dialogId = button.dataset.dialog;
            const targetDialog = document.getElementById(dialogId);
            if (targetDialog instanceof HTMLDialogElement) {
                dialogListModal.close();
                targetDialog.showModal();
            }
        }
    });
}
