/**
 * @module cta
 * @description Module aims to collect methods to get and post user's and context data to google analytics.
 */
import {CookieManager} from '../modules/cookie.js';
import {Auth} from '../modules/auth.js';

/**
 * Is Google Analytics loaded (can be blocked by AdBlock)
 * @private
 * @returns {boolean}
 */
function isGaAvailable() {
    return !!(window.ga && window.ga.loaded);
}

/**
 * Return an alias for course system page using current location path
 * @private
 * @param {string} urlPath
 * @returns {?string}
 */
function getCourseCardListName(urlPath) {
    if (/^\/?$/i.test(urlPath)) {
        return 'courseCardOnFrontPage';
    }

    if (/^\/courses\/?$/i.test(urlPath)) {
        return 'courseCardOnCourseMainPage';
    }

    if (/^\/literature\/article\/overview\/?$/i.test(urlPath)) {
        return 'courseCardOnUXDailyPage';
    }

    if (/^\/literature\/article\/.+$/i.test(urlPath)) {
        return 'courseCardOnUXDailyPage';
    }

    if (/^\/literature\/book\/.+\/?$/i.test(urlPath)) {
        return 'courseCardOnBookChapterPage';
    }

    return null;
}

/**
 * Return course card information
 * @private
 * @param {HTMLElement} card
 * @returns {{name: string, id: number, variant: string, url: string}}
 */
function getCourseCardInfo(card) {
    const courseName = card.querySelector('.js-cardTitle').textContent;
    const courseId = parseInt(card.dataset.courseId);
    const variant = parseInt(card.dataset.percentageBooked) === 100 ? 'closed' : 'open';
    const url = card.dataset.locationPathname;

    return {
        name: courseName,
        id: courseId,
        variant: variant,
        url: url,
    };
}

/**
 * Collect course card clicks and push them to GA
 * @param {Event} clickEvent
 * @public
 */
function collectCourseCardClick(clickEvent) {
    if (!isGaAvailable()) {
        return;
    }

    const card = clickEvent.target.closest('.card--course');

    const cardInfo = getCourseCardInfo(card);

    const currentUrl = window.location.pathname.toLowerCase();
    let listName = getCourseCardListName(currentUrl);
    if (listName === null) {
        listName = `N/A, URL: ${currentUrl}`;
    }

    /** @type {number} */
    const courseCards = [...document.querySelectorAll('.card--course')];
    const cardIndexAtPage = courseCards.indexOf(card) + 1;

    window.dataLayer.push({
        event: 'productClick',
        userId: Auth.id(),
        ecommerce: {
            click: {
                actionField: {
                    list: listName,
                },
                products: [
                    {
                        name: cardInfo.name,
                        id: cardInfo.id,
                        variant: cardInfo.variant,
                        position: cardIndexAtPage,
                    },
                ],
            },
        },
    });
}

/** [no description; please add] */
function listenImportantClicks() {
    // Handles the click event of a course card.

    document
        .querySelectorAll('[data-gtm-click]')
        .forEach((element) => element.addEventListener('click', collectCourseCardClick));

    document.querySelectorAll('[data-gtm-cta]').forEach((element) =>
        element.addEventListener('click', () => {
            const ctaName = element.dataset.gtmCta;

            window.dataLayer = window.dataLayer || {};
            window.dataLayer.push({
                event: 'cta_click',
                ctaName,
            });

            CookieManager.write(
                'ctaName',
                ctaName,
                {
                    days: 1 / (24 * 12),
                } // store 5 minutes only
            );
        })
    );
}

document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', listenImportantClicks)
    : listenImportantClicks();
